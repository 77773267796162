<template>
  <div>
    <div class="services-page">
      <div class="round-right"></div>
      <div class="round-left"></div>
      <div class="container">
        <div class="row about-us-wrapper">
          <div class="col">
            <div class="services-block-title">
              Наши <span>услуги</span>
            </div>
            <div class="about-us-desc">
              Наш штат состоит из проектных менеджеров, продуктологов, бизнес-аналитиков, дизайнеров, разработчиков, тестировщиков и DevOps
            </div>
            <div class="about-us-contacts">
              <a class="contact-email">
                it@ts-technology.kz
              </a>
              <a class="contact-telegram">
                +7 706 708 0424
              </a>
              <a class="contact-address">
                Республика Казахстан, г. Алматы, пр-т Абая 68/74
              </a>
            </div>
          </div>
          <div class="col">
            <div class="workers-img" :style="{ 'background-image': `url('./css/images/services-back.png')` }">

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container">
        <div class="partners">
          <div class="service-block-item">
            <div class="service-block-logo">
              <div class="service-block-img" :style="{ 'background-image': `url('./css/images/img_service_1.svg')` }">

              </div>
            </div>
            <div class="service-block-desc">
              <div class="service-block-title">
                Разработка под ключ
              </div>
              <div class="service-block-text">
                Наши компетенции включают в себя дизайн, разработку, аналитику, серверную архитектуру, запуск, внедрение и
                поддержку проекта
              </div>
            </div>
          </div>
          <div class="service-block-item">
            <div class="service-block-logo">
              <div class="service-block-img" :style="{ 'background-image': `url('./css/images/img_service_2.svg')` }">

              </div>
            </div>
            <div class="service-block-desc">
              <div class="service-block-title">
                Аутсорс&Аутстафф
              </div>
              <div class="service-block-text">
                Возьмем задачу или проект под реализацию или выделим наших сотрудников для выполнения задач за фиксированную или почасовую оплату
              </div>
            </div>
          </div>
          <div class="service-block-item">
            <div class="service-block-logo">
              <div class="service-block-img" :style="{ 'background-image': `url('./css/images/img_service_3.svg')` }">

              </div>
            </div>
            <div class="service-block-desc">
              <div class="service-block-title">
                Поддержка и модернизация легаси проектов
              </div>
              <div class="service-block-text">
                Проект был разработан давно, интегрирован глубоко, а дорабатывать и поддерживать некому? Можем решить! Изучаем структуру и процесс проекта, дорабатываем и поддерживаем, даем рекомендации по дальнейшему развитию проекта.
              </div>
            </div>
          </div>
          <div class="service-block-item">
            <div class="service-block-logo">
              <div class="service-block-img" :style="{ 'background-image': `url('./css/images/img_service_4.svg')` }">

              </div>
            </div>
            <div class="service-block-desc">
              <div class="service-block-title">
                Поддержка
              </div>
              <div class="service-block-text">
                Осуществляем техническую поддержку проекта, исправление багов и прием обращений от пользователей.
              </div>
            </div>
          </div>
          <div class="service-block-item">
            <div class="service-block-logo">
              <div class="service-block-img" :style="{ 'background-image': `url('./css/images/img_service_5.svg')` }">

              </div>
            </div>
            <div class="service-block-desc">
              <div class="service-block-title">
                Серверная архитектура
              </div>
              <div class="service-block-text">
                Разворачиваем физические или виртуальные машины, формируем требования к мощностям, настраиваем конфигурации, резервное копирование, широту канала, протоколы взаимодействия, проводим нагрузочное тестирование
              </div>
            </div>
          </div>
          <div class="service-block-item">
            <div class="service-block-logo">
              <div class="service-block-img" :style="{ 'background-image': `url('./css/images/img_service_6.svg')` }">

              </div>
            </div>
            <div class="service-block-desc">
              <div class="service-block-title">
                Интеграции
              </div>
              <div class="service-block-text">
                Проведем интеграции с частными и с гос. сервисами. Также можем написать интеграционный сервис для вашего проекта.
              </div>
            </div>
          </div>
          <div class="service-block-item">
            <div class="service-block-logo">
              <div class="service-block-img" :style="{ 'background-image': `url('./css/images/img_service_7.svg')` }">

              </div>
            </div>
            <div class="service-block-desc">
              <div class="service-block-title">
                Колл-центр
              </div>
              <div class="service-block-text">
                Наш колл-центр для решения ваших задач. Поддержка или обзвон клиентов и пользователей, на казахском и русском языках, по вашим скриптам, с ежедневными отчетами
              </div>
            </div>
          </div>
          <div class="service-block-item">
            <div class="service-block-logo">
              <div class="service-block-img" :style="{ 'background-image': `url('./css/images/img_service_8.svg')` }">

              </div>
            </div>
            <div class="service-block-desc">
              <div class="service-block-title">
                Заинтересованы? Напишите нам
              </div>
              <div class="service-block-text">
                <div class="service-block-btn">
                  Связаться
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>

import Vue from "vue";

export default {
  name: 'Services',
}
</script>
<style>

</style>
